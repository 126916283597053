import React from "react";
import styled from 'styled-components';
import { useStoreon } from "storeon/react";

import UseIcon from "./UseIcon";
import { breakpoint } from './theme';

const FooterTag = styled.footer`
  background: white;
  text-align: center;
  flex-shrink: 0;
`;

const FooterInner = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 16px;
  text-align: left;

  ${breakpoint.tabletUp} {
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: flex-start;
  }
`;

const Menu = styled.div`
  display: flex;
  margin: 20px 0;
`;

const Col = styled.div`
  display: inline-flex;
  flex-direction: column;
  min-width: 125px;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
`;

const Title = styled.h3`
  font-weight: 500;
  font-size: 14px;
  color: #171F46;
  margin-bottom: 6px;
`;

const FooterLink = styled.a`
  text-decoration: none;
  color: #70767e;
  font-size: 14px;
  padding: 5px 0;
  margin-bottom: 1px;
`;

const Bottom = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 25px 0 10px;

  ${breakpoint.tabletUp} {
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 45px 0 0;
  }
`;

const CopyRight = styled.div`
  font-size: 10px;
  color: #171F46;
  margin-left: 18px;
  line-height: 1.6;

  ${breakpoint.mobileOnly} {
    br {
      display: none
    }
  }

  ${breakpoint.tabletUp} {
    margin: 10px 0 0;
  }
`;

const Text = styled.div`
  padding: 0 16px 16px;
  font-size: 11px;
  text-align: left;
  max-width: 1024px;
  margin: 0 auto;

  ${breakpoint.tabletUp} {
    padding: 0 10px 16px;
  }
`;

const Footer = () => {
  const { legalLinks } = useStoreon('legalLinks');

  return (
    <FooterTag>
      <FooterInner>
        <Menu>
          <Col>
            <Title>Legal</Title>
            {legalLinks && legalLinks.map((item, key) => (
              <FooterLink key={key} href={item.url}>{item.title}</FooterLink>
            ))}
          </Col>
        </Menu>
        <Bottom>
          <UseIcon name="IconFooterLogo" width={57} height={18} />
          <CopyRight>© 2021-2022 GamesChart</CopyRight>
        </Bottom>
      </FooterInner>
      <Text>
        GamesChart.top does not identify itself as owner of trademarks, company names, logos, other objects of intellectual
        property rights, as well as information about products (except for objects of intellectual property rights owned
        by GamesChart.top) placing them on the website.
      </Text>
    </FooterTag>
  )
};

export default Footer;
