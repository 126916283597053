import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import theme, { breakpoint } from './theme';
import { useStoreon } from 'storeon/react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import getClosest from '../utils/getClosest';

import UseIcon from './UseIcon';
import MenuButton from './MenuButton';
import MenuSubcat from './MenuSubcat';
import MenuText from './MenuText';

const MenuTag = styled.div`
  background: ${theme.color.bg};
  width: 250px;
  height: calc(100% - 43px);
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 2;

  transition: 
      transform 0.2s ease, 
      box-shadow 0.2s ease,
      opacity 0.15s linear,
      visibility 0s linear ${props => props.active ? '0s' : '0.2s'};
      
  transform: ${props => props.active ? 'translate(0, 0)' : 'translate(100%, 0)'};
  box-shadow: ${props => props.active ? '0px 0px 101px 0px #FCD5BD, 0 0 500px 3000px #171f46cc' : 'none'};
  opacity: ${props => props.active ? '1' : '0'};
  visibility: ${props => props.active ? 'visible' : 'hidden'};

  ${breakpoint.tabletUp} {
    height: calc(100% - 61px);
  }

  @media (min-width: 1024px) {
    right: 50%;
    margin-right: -512px;
    
    &::after {
      content: '';
      background: ${theme.color.border};
      width: 1px;
      height: 100%;
      right: 0;
      top: 0;
      position: absolute;
    }
  }
`;

const Scrolled = styled.nav`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Top = styled.div`
  padding: 22px 19px 0;
  text-align: center;
`;

const AppButton = styled.a`
  color: white;
  text-decoration: none;
  border-radius: 5px;
  height: 50px;
  display: inline-flex;
  background: ${theme.color.text};
  justify-content: center;
  align-items: center;
  font-size: 13px;
  padding: 0 23px;
`;

const AppButtonText = styled.div`
  font-weight: 500;
  margin-left: 13px;
  text-align: left;
`;

const AppButtonTinn = styled.div`
  font-weight: 400;
  letter-spacing: 0.05em;
`;

const Icon = styled.span`
  min-width: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const Category = styled.div`
  position: relative;
`;

const Legal = styled.div`
  margin: 23px 10px 0;
  text-align: left;
`;

const LegalLink = styled.a`
  text-decoration: none;
  color: ${theme.color.text};
  font-size: 14px;
  padding: 4px 0;
  display: block;
`;

const Footer = styled.div`

`;

const Logo = styled.div`
  text-align: center;
  padding: 33px 0;
`;

const Copyright = styled.div`
  background: ${theme.color.bright};
  color: white;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.18;
  padding: 23px 20px 17px;
`;

const Menu = () => {
  const menuRef = useRef(null);
  const scrolledRef = useRef(null);

  const { dispatch, menu } = useStoreon('menu');
  const { legalLinks } = useStoreon('legalLinks');
  const activeRef = useRef(menu.open);
  const setActive = data => {
    activeRef.current = data;
  };

  useEffect(() => {
    setActive(menu.open);

    if (menu.open) {
      disableBodyScroll(scrolledRef.current);
    } else {
      enableBodyScroll(scrolledRef.current);
    }
  }, [menu.open]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClickOutside = event => {
    setTimeout(() => {
      if (menuRef.current && (!menuRef.current.contains || !menuRef.current.contains(event.target)) && activeRef.current && !getClosest(event.target, '.hamburger')) {
        dispatch('menu/change');
      }
    }, 0)
  };

  const handleLegalClick = () => {
    dispatch('menu/change');
  };

  const getItems = () => {
    const resultArray = [];
    let currentCategory = null;

    for (const item of menu.items) {
      if (item.group_type === "category") {
        if (currentCategory) {
          resultArray.push(currentCategory);
        }
        currentCategory = { ...item, subitems: [] };
      } else if (item.group_type === "subcategory" && currentCategory) {
        currentCategory.subitems.push(item);
      }
    }

    if (currentCategory) {
      resultArray.push(currentCategory);
    }

    return resultArray;
  }

  const items = getItems();

  return (
    <MenuTag active={menu.open} ref={menuRef}>
      <Scrolled ref={scrolledRef}>
        <Top>
          <AppButton target="_blank" href='https://play.google.com/store/apps/details?id=com.scorenga.app&hl=en&gl=US'>
            <UseIcon name='IconPlaymarket' />
            <AppButtonText>Download for Free<AppButtonTinn>Games App</AppButtonTinn></AppButtonText>
          </AppButton>

          <MenuText />
          {items.map((item, key) => (
            <Category key={key}>
              <MenuButton href={item.path}><Icon><UseIcon name={item.icon} /></Icon>{item.title}</MenuButton>
              {item.subitems && <MenuSubcat subitems={item.subitems} /> }
            </Category>
          ))}
          <Legal>
            {legalLinks && legalLinks.map((item, key) => (
              <LegalLink key={key} href={item.url} onClick={handleLegalClick}>{item.title}</LegalLink>
            ))}
          </Legal>
        </Top>
        <Footer>
          <Logo>
            <UseIcon name="IconMenuLogo" width={174} height={55} />
          </Logo>
          <Copyright>
            Gameschart.top does not identify itself as owner of trademarks, company names, logos, other objects of intellectual
            property rights, as well as information about products (except for objects of intellectual property rights owned
            by gameschart.top) placing them on the website.
            <br />
            <br />
            © 2021-2022 GamesChart
          </Copyright>
        </Footer>
      </Scrolled>
    </MenuTag>
  )
};

export default Menu;
