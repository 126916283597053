import React, { useState, useRef, useEffect } from "react";
import styled from 'styled-components';
import { useStoreon } from "storeon/react";
import MHelper from '../utils/MHelper.js';

import sendAnalyticsEvent from "../utils/sendAnalyticsEvent.js";
import getCookie from "../utils/getCookie.js";
import delayedEvents from "../utils/delayedEvents.js";

const AdvTag = styled.div`
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  min-width: 250px;
  display: block;
  text-align: center;
  padding: 10px 0;
  min-height: ${ ({minHeight}) =>  minHeight ? minHeight : '250'}px;
`;

const Inner = styled.div`
  background: #D7DFE9;
  height: 100%;
  width: 100%;
  min-height: ${ ({minHeight}) =>  minHeight ? minHeight : '250'}px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Adv = (props) => {
  const bannerInitialized = useRef(false);
  const analyticsSent = useRef(false);
  const [ stub, setStub ] = useState(false);
  const AdvRef = useRef(null);
  const { ads, dispatch } = useStoreon('ads');

  const getKey = () => {
    return 'b' + Math.floor(Math.random() * 1000);
  }

  const [ key, setKey ] = useState(getKey());

  useEffect(() => {
    if (props.lazy)
      initObserver().observe(AdvRef.current);
    else {
      show();
      MHelper.addEventOnOrientationChange(refresh);
    }

    return () => {
      MHelper.removeEventOnOrientationChange(refresh);
    }

  }, []);

  const initObserver = () => {
    if(MHelper.ifSSR) return false;

    return new IntersectionObserver(
      entries => {
        const { isIntersecting } = entries[0];

        if (isIntersecting) {
          if (bannerInitialized.current) return false;
          bannerInitialized.current = true;
          init();
        }
      },
      { threshold: [0.5] }
    )
  };

  const eventObserver = () => {
    if (MHelper.ifSSR) return false;

    return new IntersectionObserver(
      entries => {
        const { isIntersecting, intersectionRatio } = entries[0];
        if ( isIntersecting && intersectionRatio >= 0.5 && !analyticsSent.current ) {
          setTimeout(() => {
            if (AdvRef.current) {
              analyticsSent.current = true;
              const adEl = AdvRef.current.firstElementChild;
  
              // adsense
              if(adEl && adEl.tagName === "INS") {
                const status = adEl.dataset.adStatus;
                if(status === 'filled') sendAction();
                else if (status !== 'unfilled') attrObserver().observe(adEl, { attributes: true });
              }
  
              // adx
              if(adEl && adEl.tagName === "DIV") {
                if (adEl.querySelector('iframe')) sendAction();
                else treeObserver().observe(adEl, {subtree: true, childList: true});
              }
            }
          }, 1000);
        }

      },
      { threshold: [0, 0.5] }
    )
  }

  const attrObserver = () => {
    if (MHelper.ifSSR) return false;

    const observer = new MutationObserver(
      entries => {
        const entry = entries[0];
        if (
          entry.type === "attributes" &&
          entry.target.dataset.adStatus &&
          entry.target.dataset.adStatus === "filled"
        ) {
          sendAction();
          observer.disconnect();
        }
      }
    );

    return observer;
  }

  const treeObserver = () => {
    if (MHelper.ifSSR) return false;

    return new MutationObserver(
      mutationList => {
        mutationList.forEach(mutation => {
          mutation.addedNodes.forEach(node => {
            if(node.tagName === "IFRAME") {
              sendAction();
              treeObserver().disconnect();
            };
          });
        });
      }
    );
  }

  const sendAction = () => {
    const {analyticsAction, analyticsActionSpecial} = props;
    sendAnalyticsEvent(analyticsAction);

    if (analyticsActionSpecial) {
      if (getCookie('shownInterstitial'))
        sendAnalyticsEvent(analyticsActionSpecial);
      else
        delayedEvents().add({name: analyticsActionSpecial});
    }
  }

  const show = () => setTimeout(() => {
    bannerInitialized.current = false;
    if (AdvRef) {
      init();
      if (props.analyticsAction) eventObserver().observe(AdvRef.current);
    }
  }, 1000);

  const refresh = () => {
    if (AdvRef) {
      bannerInitialized.current = false;
      setKey(getKey());
      setTimeout(() => init(), 1000);
    }
  }

  const init = () => {
    const { id } = props.slot;
    const currentAdsLoaded = ads.loaded.includes(id);

    if (props.slot && AdvRef.current) {
      if (!currentAdsLoaded) dispatch('ads/add', props.slot.id);

      AdvRef.current.innerHTML = html();

      if (!checkAdx() || !currentAdsLoaded) {
        let scriptBody = document.createElement('script');
        scriptBody.appendChild(document.createTextNode(jscript()));
        AdvRef.current.appendChild(scriptBody);
      }

      if (window.googletag && window.googletag.pubads && currentAdsLoaded) {
        window.googletag.pubads().refresh();
      }

    } else if (AdvRef.current) {
      setStub(true);
    }
  }

  const regexp = () => {
    return /<script\b[^>]*>([\s\S]*?)<\/script>/;
  }

  const isMatchReplaceable = () => {
    return window.matchMedia(props.replaceable).matches;
  }

  const variantCode = () => {
    const { slot, codeHor } = props;
    return isMatchReplaceable() ? codeHor : slot.slot_code;
  }

  const html = () => {
    return variantCode().replace(regexp(), '');
  }

  const jscript = () => {
    const scriptCode = regexp().exec(props.slot.slot_code);
    if (scriptCode && scriptCode.length === 2)
      return scriptCode[1];
  }

  const checkAdx = () => {
    return jscript() && jscript().indexOf('googletag.cmd.push') > -1;
  }

  return (
    <AdvTag minHeight={props.minHeight} ref={AdvRef} className={props.className} key={key}>
      <Inner minHeight={props.minHeight}>
        {stub && 'ADV'}
      </Inner>
    </AdvTag>
  )
}

export default Adv;
