import React, { useEffect, useState, useRef } from 'react';
import ajaxGet from '../utils/ajaxGet';
import { useStoreon } from "storeon/react";
import styled from "styled-components";
import Loader from './Loader';
import PageLoader from './PageLoader';
import theme, { breakpoint } from './theme';
import debounce from '../utils/debounce';
import setMeta from '../utils/setMeta';
import setPageKeys from '../utils/setPageKeys';
import getJsonPath from '../utils/getJsonPath';
import handleScroll from '../utils/handleScroll';

import sendAnalyticsEvent from "../utils/sendAnalyticsEvent";

import Thumb from "./Thumb";
import Adv from "./Adv";
import BtnDlGame from "./BtnDlGame";
import FoldedBlock from './FoldedBlock';

import Info from './Info';
import AdditionalInfo from './AdditionalInfo';
import Gallery from './Gallery';
import RelatedBlock from './RelatedBlock';
import checkHeight from '../utils/checkHeight';

const Page = styled.div`
  max-width: 1024px;
  margin: 0 auto;
`;

const PageTitle = styled.h1`
  font-size: 18px;
  font-weight: 500;
  margin: 21px 10px 9px;

  ${breakpoint.tabletUp} {
    margin: 13px 8px 15px;
  }
`;

const TopContainer = styled.div`
  ${breakpoint.tabletUp} {
    display: flex;
    width: 100%;
    flex: 100% 0 0;
    align-items: center;
    padding-right: 10px;
  }
`;

const Head = styled.div`
  display: flex;
  padding: 8px 10px;

  ${breakpoint.tabletUp} {
    align-items: center;
    flex: 0 0.8 434px;
  }
`;

const ColLeft = styled.div`
  flex: 80px 0 0;
  position: relative;

  ${breakpoint.tabletUp} {
    flex-basis: 120px;
  }
`;

const Title = styled.h1`
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 500;
  margin-bottom: 6px;

  ${breakpoint.tabletUp} {
    font-size: 18px;
  }
`;

const ColRight = styled.div`
  padding-left: 16px;
`;

const Caterory = styled.div`
  margin-bottom: 8px;
`;

const DownloadGameTop = styled.div`
  margin-top: 4px;
  max-width: 260px;
  margin-left: auto;
  margin-right: auto;

  ${breakpoint.tabletUp} {
    max-width: none;
    flex: 0 1 500px;
  }
`;

const DownloadGame = styled(BtnDlGame)`
  margin-bottom: 8px;
  display: none;

  ${breakpoint.tabletUp} {
    display: inline-flex;
    border-radius: 4px;
    overflow: hidden;
  }
`;

const DownloadGameMobile = styled.div`
  max-width: 260px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;

  ${breakpoint.tabletUp} {
    display: none;
  }
`;

const Nav = styled.nav`
  margin: 10px 10px 10px;
`;

const NavItem = styled.button`
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  border: 0;
  padding: 9px 0;
  background: transparent;
  margin-right: 24px;
  font-family: inherit;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  border-bottom: 1px solid ${({ selected }) => selected ? theme.color.bright : 'transparent' };
  color: ${({ selected }) => selected ? theme.color.text : theme.color.textGray };

  &:last-child {
    margin-right: 0%;
  }
`;

const Tab = styled.div`
  display: ${({ selected }) => selected ? 'block' : 'none'};
  margin: 10px;

  ${breakpoint.tabletUp} {
    margin: 18px 10px;
  }
`;

const TabFlex = styled.div`
  display: ${({ selected }) => selected ? 'block' : 'none'};
  margin: 10px;

  ${breakpoint.tabletUp} {
    margin: 18px 10px;
    display: ${({ selected }) => selected ? 'flex' : 'none'};
    justify-content: space-between;
  }
`;

const InfoCol = styled.div`
  ${breakpoint.tabletUp} {
    flex: 45% 0 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  ${breakpoint.desktopUp} {
    flex: 33% 0 0;
  }
`;

const TabDownload = styled.div`
  margin: 0 -10px;

  ${breakpoint.tabletUp} {
    margin: 0;
    visibility: ${({ folded }) => folded ? 'visible' : 'hidden' };
    opacity: ${({ folded }) => folded ? '1' : '0' };
    transition: 
      opacity 0.2s linear ${({ folded }) => folded ? '0.2s' : '0s' },
      visibility 0.2s linear ${({ folded }) => folded ? '0s' : '0.2s' },
      max-height 0s linear 0.2s;
    max-height: ${({ folded }) => folded ? '48px' : '0' };
  }
`;

const StyledLoader = styled(Loader)`
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  width: 35px;
  height: 35px;
  margin-top: -40px;
  margin-bottom: 5px;
`;

const AppButton = styled.a`
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 0;
  outline: none;
`;

const PageApp = ({ slug }) => {
  const { dispatch, appPage, ads } = useStoreon('appPage', 'ads');

  const [ selectedTab, setSelectedTab ] = useState(0);
  const [ gallerySlug, setGallerySlug ] = useState(slug);
  const [folded, setFolded] = useState(false);

  const count = useRef(appPage ? appPage.related.length : 0);
  const isFetching = useRef(false);
  const [ pageFetching, setPageFetching ] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const scroller = useRef(null);

  const currentSlug = useRef(slug);

  const [ timeout ] = useState(1500);

  useEffect(() => {
    window.addEventListener('scroll', debouncedScrollListener);
    return () => {
      window.removeEventListener('scroll', debouncedScrollListener);
      if(window.googletag && window.googletag.pubads) {
        window.googletag.pubads().clear();
      }
      // dispatch('appPage/cleen');
    }
  }, []);

  useEffect(() => {
    if (appPage && appPage.id === +slug) {
      setMeta(appPage.pageTitle, appPage.pageDescription, appPage.keywords);
      setPageKeys(appPage.pageKeys);
      checkHeight(loadingElements);
    } else {
      setPageFetching(true);
      window.scrollTo(0, 0);
      ajaxGet(getJsonPath())
        .then(data => {
          setSelectedTab(0);
          count.current = data.appPage.related.length;
          dispatch('appPage/success', data.appPage);
          setMeta(data.appPage.pageTitle, data.appPage.pageDescription, data.appPage.keywords);
          setPageKeys(data.appPage.pageKeys);
          setGallerySlug(slug);
          currentSlug.current = slug;
          setPageFetching(false);
          setTimeout(() => checkHeight(loadingElements), 0);
        })
        .catch(error => {
          dispatch('appPage/fail');
        });
    }
  }, [slug]);

  const loadingElements = () => {
    isFetching.current = true;
    setShowLoader(true);
    const { pathname } = new URL(window.location.origin + '/data/thumbs/apps/' + currentSlug.current);

    ajaxGet(`${pathname}?offset=${count.current}&limit=12`)
      .then(data => {
        isFetching.current = false;
        setShowLoader(false);
        count.current = count.current + data.related.length;
        dispatch('appPage/add', data.related);
      })
      .catch(error => {
        dispatch('appPage/fail');
      });
  };

  const hs = () => handleScroll(scroller.current, isFetching.current, loadingElements);
  const debouncedScrollListener = debounce(hs, 150);

  const tabs = ["Gameplay", "About the game"];

  const downloadClickHandler = () => {
    sendAnalyticsEvent('GAME_SOURCE', { id: appPage.id });
  };

  const appButtonClick = () => {
    sendAnalyticsEvent('GAME_ICON_CLICK', { id: appPage.id });
  };

  return (
    <>
      {appPage && <Page ref={scroller}>
        <PageTitle>{appPage.title} - Download App Preview</PageTitle>
        {ads.slots['ztop-app-ATF'] && <Adv
          slot={ads.slots['ztop-app-ATF']}
          key={slug}
          analyticsAction="APPTOP_LOADED"
          analyticsActionSpecial="CL_APPTOP_LOADED"
        />}
        <TopContainer>
          <Head>
            <ColLeft>
              <Thumb 
                image={appPage.image}
                key={appPage.id}
                id={appPage.id}
                sizeMobile={80}
                sizeTablet={120}
              />
              <AppButton onClick={appButtonClick} href={appPage.url + '/download/'} title="Download" />
            </ColLeft>
            <ColRight>
              <Title>{appPage.title}</Title>
              {appPage.caterories && <Caterory>{appPage.caterories.map((item, key) => ((key ? ' / ' : '') + item))}</Caterory>}
              <Info 
                loaded={appPage.loaded}
                price={appPage.price}
                score={appPage.score}
                size={appPage.size}
              />
            </ColRight>
          </Head>
          <DownloadGameTop>
            <BtnDlGame url={appPage.url + '/download/'} onClick={downloadClickHandler} timeout={timeout} />
          </DownloadGameTop>
        </TopContainer>

        <Nav>
          {tabs.map((item, key) => (
            <NavItem key={key} onClick={() => setSelectedTab(key)} selected={selectedTab === key}>{item}</NavItem>
          ))}
        </Nav>

        <Tab selected={selectedTab === 0}>
          <Gallery video={appPage.video} pictures={appPage.pictures} slug={gallerySlug} />
        </Tab>

        <TabFlex selected={selectedTab === 1}>
          {appPage.description && <FoldedBlock visible={selectedTab === 1} folded={folded} setFolded={setFolded}>{appPage.description}</FoldedBlock>}

          <InfoCol>
            {appPage.info && <AdditionalInfo info={appPage.info} />}
            <TabDownload folded={folded}>
              <DownloadGame url={appPage.url + '/download/'} onClick={downloadClickHandler} timeout={timeout} />
            </TabDownload>
          </InfoCol>
          
        </TabFlex>
        <DownloadGameMobile>
          <BtnDlGame url={appPage.url + '/download/'} onClick={downloadClickHandler} timeout={timeout} />
        </DownloadGameMobile>

        {appPage.related && <RelatedBlock slug={slug} related={appPage.related} adPosition={3} adName="ztop-app-BTF" />}
        {showLoader && <StyledLoader />}
      </Page>
      }
      {(!appPage || pageFetching) && <PageLoader/>}
    </>
  )
};

export default PageApp;
