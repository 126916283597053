import ReactOnRails from 'react-on-rails';
import Root from '../bundles/ZisTop/Root';
import * as Sentry from '@sentry/react';
//import { BrowserTracing } from "@sentry/tracing";

import initAdxCustom from 'adx_slot_constructor';
import getCookie from "../bundles/ZisTop/utils/getCookie";
import sendAnalyticsEvent from '../bundles/ZisTop/utils/sendAnalyticsEvent';
import delayedEvents from "../bundles/ZisTop/utils/delayedEvents.js";

Sentry.init({
  dsn: "https://a8dcab08d2c14785a84154e6b9416681@traces.mynewcdn.com/6",
//integrations: [new BrowserTracing()],
//  tracesSampleRate: 0.01
});

if (typeof window !== 'undefined') {
  initAdxCustom();
  window.ui = {
    getCookie: getCookie,
    Event: sendAnalyticsEvent,
    delayedEvents: delayedEvents
  };
}

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({ Root });
