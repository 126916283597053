import React, { useState } from 'react';
import styled from 'styled-components';
import theme from './theme';
import UseIcon from './UseIcon';

const Text = styled.div`
  font-size: 12px;
  color: ${theme.color.text};
  margin: 20px 9px 17px 11px;
  line-height: 1.18;
  text-align: left;
  will-change: transform;
  position: relative;

  b {
    font-weight: 500;
  }
`;

const TextIn = styled.div`
  will-change: transform;
  max-height: ${props => props.opened ? '2000px' : '71px'};
  text-overflow: ${props => props.opened ? 'clip' : 'ellipsis'};
  overflow: hidden;
  display: ${props => props.opened ? 'block!important' : '-webkit-box!important'};
  -webkit-line-clamp: ${props => props.opened ? 'none' : '2'};
  -webkit-box-orient: vertical;
  white-space: normal;
  transition: ${props => props.opened ? 'max-height 1s linear' : 'max-height 0.5s cubic-bezier(0, 1, 0.5, 1)'};
`;

const TextMore = styled.button`
  will-change: transform;
  background: white;
  box-shadow: 0 0 3px 2px white;
  border: 0;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: -2px;
  transform: ${props => props.opened ? 'rotate(180deg) translate(0, -3px)' : 'rotate(0)'};
  transition: transform 0.2s ease-in-out;
  user-select: none;
  outline: none;
  height: 18px;
  width: 18px;
  padding: 0;
  border-radius: 10px;
`;

const MenuText = () => {
  const [ opened, setOpened ] = useState(false);

  const handleOpen = () => {
    setOpened(!opened);
  }

  return (
    <Text>
      <TextIn opened={opened}>
        <b>GamesChart</b> is a new search engine for Android games. Ratings of games from real users, easy navigation, and 100+
        categoriesof games such as casual, puzzle, arcade, action, racing, strategy, card, board, adventure, and
        simulation will help you quickly and freely find the desired game and download it from the official source.
        Discover the best collection of games from leading developers, and none of the newly published games are left
        behind by our algorithms.
        <br />
        You can find and download your favorite games for free at home, at work, on vacation, in the car or on the
        train, bus, boat, plane, everywhere. Wherever you are, whether in a cafe or restaurant, whatever you are
        doing, doing shopping, relaxing, or traveling, you can always use <b>GamesChart.top</b> to navigate the world of mobile
        games for Android.
        <br />
        <br />
        Also, download our app to play free online games on your mobile phone or tablet.
      </TextIn>

      <TextMore opened={opened} onClick={handleOpen}>
        <UseIcon name="IconChevron" color={opened ? theme.color.text : theme.color.bright} />
      </TextMore>
    </Text>
  )
}

export default MenuText;
